var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',{staticClass:"text-center my-5"},[_c('div',{staticClass:"spinner-border text-primary"})]):_vm._e(),(!_vm.loading && _vm.surveys && _vm.surveys.length === 0)?_c('p',{staticClass:"text-info mt-3 d-flex"},[_c('i',{staticClass:"bi bi-info-circle-fill fs-3 me-3"}),_c('span',[_vm._v(_vm._s(_vm.$t('surveysToFillIn.emptyResult')))])]):_vm._e(),(!_vm.loading && _vm.surveys && _vm.surveys.length === 0)?_c('b-button',{staticClass:"m-0",attrs:{"hidden":true,"variant":"primary"},on:{"click":_vm.onClickRetry}},[_vm._v(" "+_vm._s(_vm.$t('surveysToFillIn.retry'))+" ")]):_vm._e(),(!_vm.loading && _vm.error)?_c('b-alert',{staticClass:"mb-0 mt-3",attrs:{"variant":"danger","size":"sm","show":""}},[_vm._v(_vm._s(_vm.$t('error.couldNotLoadSurveys')))]):_vm._e(),(!_vm.loading && _vm.surveys && _vm.surveys.length > 0)?_c('div',{staticClass:"mb-0"},[_c('div',{staticClass:"mb-3"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$tc('surveysToFillIn.title', _vm.unfilledSurveys.length))+" ")]),(_vm.severalOngoingSurveys)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('surveysToFillIn.description')))])]):_c('div',[(_vm.oneOngoingSurveyThatsDone)?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('surveysToFillIn.descriptionOneSurveyOnlyUserDone'))+" ")]):_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('surveysToFillIn.descriptionOneSurveyOnly'))+" ")])])]),_c('div',[_c('PulsNavigationList',{attrs:{"items":_vm.surveysToDisplay,"didSelectItem":_vm.navigateToSurvey},scopedSlots:_vm._u([{key:"details",fn:function(slotProps){return [(
              slotProps.item.numberOfCategoriesCompleted &&
              slotProps.item.numberOfCategoriesCompleted <
                slotProps.item.numberOfCategories
            )?_c('div',{staticClass:"d-none d-md-block me-5"},[_c('div',{staticClass:"progress",staticStyle:{"background-color":"#e9ecef","min-width":"40rem","height":"8px"}},[_c('div',{staticClass:"progress-bar bg-info",style:(("width: " + ((slotProps.item.numberOfCategoriesCompleted /
                    slotProps.item.numberOfCategories) *
                  100) + "%")),attrs:{"role":"progressbar","aria-valuemax":slotProps.item.numberOfCategories}})])]):_vm._e(),(
              slotProps.item.numberOfCategoriesCompleted ==
              slotProps.item.numberOfCategories
            )?_c('div',{staticClass:"text-success"},[_vm._v(" "+_vm._s(_vm.$t('surveysToFillIn.youreDone'))+" "),_c('i',{staticClass:"ml-2 bi bi-check_circle fs-3 text-success"})]):_vm._e()]}}],null,false,670219843)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }