<template>
  <div>
    <div class="text-center my-5" v-if="loading">
      <div class="spinner-border text-primary"></div>
    </div>
    <p
      class="text-info mt-3 d-flex"
      v-if="!loading && surveys && surveys.length === 0"
    >
      <i class="bi bi-info-circle-fill fs-3 me-3"></i>
      <span>{{ $t('surveysToFillIn.emptyResult') }}</span>
    </p>
    <b-button
      :hidden="true"
      variant="primary"
      class="m-0"
      @click="onClickRetry"
      v-if="!loading && surveys && surveys.length === 0"
    >
      {{ $t('surveysToFillIn.retry') }}
    </b-button>
    <b-alert
      variant="danger"
      size="sm"
      show
      class="mb-0 mt-3"
      v-if="!loading && error"
      >{{ $t('error.couldNotLoadSurveys') }}</b-alert
    >
    <div class="mb-0" v-if="!loading && surveys && surveys.length > 0">
      <div class="mb-3">
        <h3>
          {{ $tc('surveysToFillIn.title', unfilledSurveys.length) }}
        </h3>
        <div v-if="severalOngoingSurveys">
          <p class="mb-0">{{ $t('surveysToFillIn.description') }}</p>
        </div>
        <div v-else>
          <p v-if="oneOngoingSurveyThatsDone" class="mb-0">
            {{ $t('surveysToFillIn.descriptionOneSurveyOnlyUserDone') }}
          </p>
          <p v-else class="mb-0">
            {{ $t('surveysToFillIn.descriptionOneSurveyOnly') }}
          </p>
        </div>
      </div>
      <div>
        <PulsNavigationList
          :items="surveysToDisplay"
          :didSelectItem="navigateToSurvey"
        >
          <template #details="slotProps">
            <div
              v-if="
                slotProps.item.numberOfCategoriesCompleted &&
                slotProps.item.numberOfCategoriesCompleted <
                  slotProps.item.numberOfCategories
              "
              class="d-none d-md-block me-5"
            >
              <div
                class="progress"
                style="background-color: #e9ecef; min-width: 40rem; height: 8px"
              >
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  :style="`width: ${
                    (slotProps.item.numberOfCategoriesCompleted /
                      slotProps.item.numberOfCategories) *
                    100
                  }%`"
                  :aria-valuemax="slotProps.item.numberOfCategories"
                ></div>
              </div>
            </div>

            <div
              v-if="
                slotProps.item.numberOfCategoriesCompleted ==
                slotProps.item.numberOfCategories
              "
              class="text-success"
            >
              {{ $t('surveysToFillIn.youreDone') }}
              <i class="ml-2 bi bi-check_circle fs-3 text-success"></i>
            </div>
          </template>
        </PulsNavigationList>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime, Settings } from 'luxon'
import { PulsNavigationList } from '@puls-solutions/puls-ui-components'

export default {
  name: 'SurveysToFillIn',
  components: {
    PulsNavigationList
  },
  props: {
    publishedSurveys: Array
  },
  computed: {
    surveys() {
      //
      return this.publishedSurveys.map((survey) => {
        const isAfter =
          DateTime.fromISO(survey.details.end_date) > DateTime.now()

        return {
          ...survey,
          isAfter,
          title: this.$tr(survey.details.messages, 'title'),
          end_date: survey.details.end_date,
          endsText:
            (isAfter
              ? this.$t('surveysToFillIn.endsIn')
              : this.$t('surveysToFillIn.ended')) +
            ' ' +
            DateTime.fromISO(survey.details.end_date).toLocaleString(
              DateTime.DATE_FULL
            )
        }
      })
    },
    surveysToDisplay() {
      return (this.surveys || []).map((survey) => ({
        ...survey,
        title: this.$tr(survey.details.messages, 'title'),
        text: survey.endsText,
        to: `/surveys/#/load/${survey.name}`
      }))
    },
    unfilledSurveys() {
      return this.surveys.filter((survey) => {
        return survey.numberOfCategoriesCompleted < survey.numberOfCategories
      })
    },
    severalOngoingSurveys() {
      return this.surveys ? this.surveys.length > 1 : false
    },
    oneOngoingSurveyThatsDone() {
      return this.surveys
        ? this.surveys.length === 1 &&
            this.surveys[0].numberOfCategoriesCompleted ===
              this.surveys[0].numberOfCategories
        : false
    },
    loading() {
      return this.$store.state.surveys.loading
    },
    error() {
      return this.$store.state.surveys.error
    },
    currentLocale() {
      return this.$i18n.locale
    }
  },
  methods: {
    getDayRemaining() {
      // TODO
      return null
    },
    onClickRetry() {
      this.loadSurveys()
    },
    timeAgo(date) {
      const now = DateTime.local()
      const past = DateTime.fromJSDate(date)
      const diff = now.diff(past, 'days')
      return diff
    },
    navigateToSurvey(survey) {
      window.location = `/surveys/#/load/${survey.name}`
    }
  },
  mounted() {
    Settings.defaultLocale = this.currentLocale
  }
}
</script>

<style lang="scss" scoped>
.main-panel > .content {
  margin-top: 0;
}

.remaining {
  color: #888;
}

.survey-item {
  // .name {
  //   font-size: 16px;
  // }

  .name,
  .complete {
    width: 100%;
    height: 24px;
  }
}
</style>
